import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';

import { Notification } from '../../utils/Notifications/Notification';
import { CHINH_SUA_TRANG_THAI_DON_HANG_TRANG_KET_THUC_SAGA, LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA, SET_DANH_SACH_DON_HANG_TRANG_KET_THUC } from '../types/KetThucType';
import { ketThucService } from '../../services/ketThucService';


function* layDanhSachDonHangTrangKetThucSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return ketThucService.layDanhSachDonHangPhanTrangAPI(page, pageSize, filters?.customerName, filters?.customerPhone, filters?.orderStatus);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_DON_HANG_TRANG_KET_THUC,
                danhSachDonHang: data?.data?.data,
                totalCount: data?.data?.totalCount,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Đơn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachDonHangTrangKetThucSaga() {
    yield takeLatest(LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA, layDanhSachDonHangTrangKetThucSaga)
}

function* chinhSuaTrangThaiDonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return ketThucService.chinhSuaTinhTrangDonHangAPI(action?.idDonHang, action?.idTrangThai);
        });


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_DON_HANG_TRANG_KET_THUC_SAGA,
                page: action?.page,
                pageSize: action?.pageSize,
                filters: action?.filters,
            })

            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Tình Trạng Đơn Hàng Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Tình Trạng Đơn Hàng Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaTrangThaiDonHangSaga() {
    yield takeLatest(CHINH_SUA_TRANG_THAI_DON_HANG_TRANG_KET_THUC_SAGA, chinhSuaTrangThaiDonHangSaga)
}
