import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Tooltip,
  Input,
} from "antd";
import { FormOutlined, SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useLocation, useHistory } from "react-router-dom"; // Thêm useLocation và useHistory
import { Lay_DANH_SACH_NGUON_HANG_SAGA } from "../../redux/types/NguonHangType";
import {
  LAY_DANH_SACH_ORDER_SAGA,
  XOA_ORDER_SAGA,
} from "../../redux/types/OderType";

const { RangePicker } = DatePicker;

export default function DanhSachOrder(props) {
  const dispatch = useDispatch();
  const location = useLocation(); // Lấy thông tin URL hiện tại
  const history = useHistory(); // Để thay đổi URL

  const { totalCountOrder, danhSachTinhTrangOrder, danhSachOrder } =
    useSelector((state) => state.OrderReducer);
  const { danhSachNguonHang } = useSelector((state) => state.NguonHangReducer);

  // Hàm để lấy filter và pagination từ URL
  const getParamsFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return {
      filters: {
        maOrder: params.get("maOrder") || "",
        label: params.get("label") || "",
        orderStatus: params.get("orderStatus") || "",
        startDate: params.get("startDate") || "",
        endDate: params.get("endDate") || "",
      },
      pagination: {
        current: parseInt(params.get("page")) || 1,
        pageSize: parseInt(params.get("pageSize")) || 30,
      },
    };
  };

  const [filters, setFilters] = useState(getParamsFromUrl().filters);
  const [pagination, setPagination] = useState(getParamsFromUrl().pagination);

  // Đồng bộ URL khi filter hoặc pagination thay đổi
  const updateUrl = (newFilters, newPagination) => {
    const params = new URLSearchParams();
    if (newFilters.maOrder) params.set("maOrder", newFilters.maOrder);
    if (newFilters.label) params.set("label", newFilters.label);
    if (newFilters.orderStatus)
      params.set("orderStatus", newFilters.orderStatus);
    if (newFilters.startDate) params.set("startDate", newFilters.startDate);
    if (newFilters.endDate) params.set("endDate", newFilters.endDate);
    params.set("page", newPagination.current);
    params.set("pageSize", newPagination.pageSize);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  // Fetch dữ liệu ban đầu khi mount
  useEffect(() => {
    dispatch({ type: Lay_DANH_SACH_NGUON_HANG_SAGA });
    const { filters: urlFilters, pagination: urlPagination } =
      getParamsFromUrl();
    dispatch({
      type: LAY_DANH_SACH_ORDER_SAGA,
      page: urlPagination.current,
      pageSize: urlPagination.pageSize,
      filters: urlFilters,
    });
  }, [dispatch]);

  // Fetch dữ liệu khi URL thay đổi
  useEffect(() => {
    const { filters: urlFilters, pagination: urlPagination } =
      getParamsFromUrl();
    setFilters(urlFilters);
    setPagination(urlPagination);
    dispatch({
      type: LAY_DANH_SACH_ORDER_SAGA,
      page: urlPagination.current,
      pageSize: urlPagination.pageSize,
      filters: urlFilters,
    });
  }, [location.search, dispatch]);

  const handleSearch = () => {
    const newPagination = { ...pagination, current: 1 }; // Reset về trang 1 khi tìm kiếm
    setPagination(newPagination);
    updateUrl(filters, newPagination); // Cập nhật URL
  };

  const handleResetSearch = () => {
    const resetFilters = {
      maOrder: "",
      label: "",
      orderStatus: "",
      startDate: "",
      endDate: "",
    };
    const newPagination = { ...pagination, current: 1 };
    setFilters(resetFilters);
    setPagination(newPagination);
    updateUrl(resetFilters, newPagination); // Cập nhật URL
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    updateUrl(filters, newPagination); // Cập nhật URL
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Order");
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
      width: 10,
    },
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Mã order | Tracking | NH",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => (
        <>
          <div>
            Order: <span className="fw-bold">{record?.MaOrder}</span>
          </div>
          <div>
            Tracking: <span className="fw-bold">{record?.TrackingNumber}</span>
          </div>
          <div>
            Nguồn hàng: <span className="fw-bold">{record?.TenNguonHang}</span>
          </div>
        </>
      ),
    },
    {
      title: "Số Lượng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => (
        <>
          <div>
            SĐ: <span className="fw-bold">{record?.SoDon}</span>
          </div>
          <div>
            SP: <span className="fw-bold">{record?.SoSanPham}</span>
          </div>
        </>
      ),
    },
    {
      title: "Tiền",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => (
        <>
          <div>
            Số Tiền:{" "}
            <span className="fw-bold">
              {record?.SoTien?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: true,
              }).replace(/\./g, ",")}
            </span>
          </div>
          <div>
            Giá Web:{" "}
            <span className="fw-bold">
              {record?.TongGiaWeb?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: true,
              }).replace(/\./g, ",")}
            </span>
          </div>
          <div>
            Ship Web:{" "}
            <span className="fw-bold">
              {record?.ShipWeb?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: true,
              }).replace(/\./g, ",")}
            </span>
          </div>
        </>
      ),
    },
    {
      title: "Ngày",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        const NgayNhanHang = record?.NgayNhanHang
          ? dayjs(record?.NgayNhanHang).format("DD/MM/YYYY")
          : "";
        const NgayNhanDonHangDuKien = record?.NgayNhanDonHangDuKien
          ? dayjs(record?.NgayNhanDonHangDuKien).format("DD/MM/YYYY")
          : "";
        const ngayTao = record?.Ngay
          ? dayjs(record?.Ngay).format("DD/MM/YYYY")
          : "";
        return (
          <>
            <div>
              Tạo: <span className="fw-bold">{ngayTao}</span>
            </div>
            <div>
              Dự Kiến: <span className="fw-bold">{NgayNhanDonHangDuKien}</span>
            </div>
            <div>
              Nhận: <span className="fw-bold">{NgayNhanHang}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Tình Trạng",
      dataIndex: "TenTinhTrangOrder",
      key: "TenTinhTrangOrder",
      render: (text, record) => (
        <>
          <div>{text}</div>
          <div className="fw-bold">{record?.TenNhanVienOrder}</div>
        </>
      ),
    },
    {
      title: "Chức Năng",
      key: "Select",
      render: (text, record) => (
        <div>
          <button
            className="btn me-2 btn-primary"
            onClick={() => {
              window.open(`/order/chi-tiet-order/${record?.Id}`, "_blank");
            }}
          >
            <Tooltip title="Chi Tiết Order">
              <i className="fa-regular fa-eye"></i>
            </Tooltip>
          </button>
          <button
            className="btn me-2 btn-primary"
            onClick={() => {
              history.push(`/order/chinh-sua-order/${record?.Id}`);
            }}
          >
            <Tooltip title="Chỉnh sửa order">
              <FormOutlined style={{ fontSize: 17 }} />
            </Tooltip>
          </button>
          <Popconfirm
            title="Xóa Order"
            description={`Bạn có chắc chắn muốn xóa order "${record?.Id}"?`}
            onConfirm={() => {
              dispatch({
                type: XOA_ORDER_SAGA,
                id: record?.Id,
                filters, // Truyền filters hiện tại
                page: pagination.current, // Truyền page hiện tại
                pageSize: pagination.pageSize, // Truyền pageSize hiện tại
              });
            }}
            onCancel={cancel}
            okText="Có"
            cancelText="Không"
          >
            <button className="btn btn-danger ml-2">
              <Tooltip title="Xóa order">
                <i className="fa-solid fa-trash"></i>
              </Tooltip>
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Danh Sách Order</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            props?.history?.push("/order/tao_moi_order");
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Order
        </button>
      </div>
      <div className="search-filters my-4 row">
        <div className="col-md-2">
          <h6>Mã Order</h6>
          <Input
            placeholder="Mã order"
            value={filters.maOrder}
            onChange={(e) =>
              setFilters({ ...filters, maOrder: e.target.value })
            }
            prefix={<SearchOutlined />}
          />
        </div>
        <div className="col-md-2 ml-md-1">
          <h6>Chọn Nguồn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            value={filters.label}
            onChange={(value) => setFilters({ ...filters, label: value })}
            placeholder="Chọn nguồn hàng"
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachNguonHang?.map((item) => (
              <Select.Option key={item?.Id} value={item?.Id}>
                {item?.Ten}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-2 ml-md-1">
          <h6>Tình Trạng Order</h6>
          <Select
            style={{ width: "100%" }}
            placeholder="Tình trạng Order"
            value={filters.orderStatus}
            onChange={(value) => setFilters({ ...filters, orderStatus: value })}
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachTinhTrangOrder?.map((item) => (
              <Select.Option value={item?.Id} key={item?.Id}>
                {item?.Ten}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3 me-md-2">
          <h6>Lọc theo Ngày Tạo</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            value={
              filters.startDate && filters.endDate
                ? [
                    dayjs(filters.startDate, "YYYY-MM-DD"),
                    dayjs(filters.endDate, "YYYY-MM-DD"),
                  ]
                : null
            }
            onChange={(dates) =>
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : "",
                endDate: dates && dates[1] ? dates[1].format("YYYY-MM-DD") : "",
              })
            }
          />
        </div>
        <div className="col-md-3 row mt-3">
          <Button className="col-md-6" type="primary" onClick={handleSearch}>
            Tìm Kiếm
          </Button>
          <Button
            className="col-md-5 ms-md-2"
            type="default"
            onClick={handleResetSearch}
          >
            Hủy Tìm Kiếm
          </Button>
        </div>
      </div>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachOrder}
        pagination={{
          ...pagination,
          total: totalCountOrder,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
