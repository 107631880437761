import "./App.css";
import { Router, Switch } from "react-router-dom";
import { HomeTemplate } from "./templates/HomeTemplate/HomeTemplate";
import Home from "./pages/Home/Home";
import { UserLoginTemplate } from "./templates/UserLoginTemplate/UserLoginTemplate";
import Login from "./pages/Login/Login";
import LoadingComponent from "./components/GlobalSetting/LoadingComponent";
import { history } from "./utils/constants/History";
import { PrivateRoute } from "./HOC/PrivateRoute";
import { LoginRoute } from "./HOC/LoginRoute";
import NotFound from "./pages/Not/NotFound";
import QuanTri from "./pages/QuanTri/QuanLyNguoiDung";
import { ProtectedRoute } from "./HOC/ProtectedRoute";
import DrawerQLDH from "./HOC/DrawerQLDH";
import KhachHang from "./pages/KhachHang/KhachHang";
import ThayDoiTyGia from "./pages/QuanLyNguonHang/ThayDoiTyGia";
import QuanLyNguonHang from "./pages/QuanLyNguonHang/QuanLyNguonHang";
import ThemDonHang from "./pages/DonHang/ThemDonHang";
import QuanLyDonHang from "./pages/DonHang/QuanLyDonHang";
import ChinhSuaDonHang from "./pages/DonHang/ChinhSuaDonHang";
import TaoMoiOrder from "./pages/Order/TaoMoiOrder";
import DanhSachOrder from "./pages/Order/DanhSachOrder";
import ChinhSuaOrder from "./pages/Order/ChinhSuaOrder";
import DanhSachOrderNhanDon from "./pages/NhanDon/DanhSachOrderNhanDon";
import ChinhSuaOrderNhanDon from "./pages/NhanDon/ChinhSuaOrderNhanDon";
import DanhSachDonShip from "./pages/GiaoHang/DanhSachDonShip";
import ThemDonShip from "./pages/GiaoHang/ThemDonShip";
import ChinhSuaDonShip from "./pages/GiaoHang/ChinhSuaDonShip";
import KetThuc from "./pages/KetThuc/KetThuc";
import DanhSachChiPhi from "./pages/ChiPhi/DanhSachChiPhi";
import ThemChiPhi from "./pages/ChiPhi/ThemChiPhi";
import ChinhSuaChiPhi from "./pages/ChiPhi/ChinhSuaChiPhi";
import ThongKeDoanhThuChiPhi from "./pages/ThongKeBaoCao/ThongKeDoanhThuChiPhi";
import DoanhThuTheoNhanVien from "./pages/ThongKeBaoCao/DoanhThuTheoNhanVien";
import ThongKeChiPhi from "./pages/ThongKeBaoCao/ThongKeChiPhi";
import ThongKeKhachHang from "./pages/ThongKeBaoCao/ThongKeKhachHang";
import QuanLyNguoiDung from "./pages/QuanTri/QuanLyNguoiDung";
import ChiTietOrder from "./pages/Order/ChiTietOrder";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import CopyDonHang from "./pages/DonHang/CopyDonHang";

function App() {
  return (
    <>
      <LoadingComponent />
      <DrawerQLDH />
      <Router history={history}>
        <Switch>
          <LoginRoute
            path="/login"
            exact
            Component={(props) => (
              <UserLoginTemplate
                {...props}
                Component={Login}
              ></UserLoginTemplate>
            )}
          ></LoginRoute>

          <PrivateRoute
            path="/"
            exact
            Component={(props) => <HomeTemplate {...props} Component={Home} />}
          />

          <ProtectedRoute
            path="/quan-tri/quan-ly-nguoi-dung"
            exact
            requiredPermission="quan_tri"
            component={(props) => (
              <HomeTemplate {...props} Component={QuanLyNguoiDung} />
            )}
          />

          <ProtectedRoute
            path="/khach-hang"
            exact
            requiredPermission="khach_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={KhachHang} />
            )}
          />

          <ProtectedRoute
            path="/quan-tri/thay-doi-ty-gia"
            exact
            requiredPermission="quan_tri"
            component={(props) => (
              <HomeTemplate {...props} Component={ThayDoiTyGia} />
            )}
          />

          <ProtectedRoute
            path="/quan-tri/quan-ly-nguon-hang"
            exact
            requiredPermission="quan_tri"
            component={(props) => (
              <HomeTemplate {...props} Component={QuanLyNguonHang} />
            )}
          />

          <ProtectedRoute
            path="/don-hang/them-moi-don-hang"
            exact
            requiredPermission="don_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={ThemDonHang} />
            )}
          />

          <ProtectedRoute
            path="/don-hang"
            exact
            requiredPermission="don_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={QuanLyDonHang} />
            )}
          />

          <ProtectedRoute
            path="/don-hang/chinh-sua-don-hang"
            exact
            requiredPermission="don_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={ChinhSuaDonHang} />
            )}
          />

          <ProtectedRoute
            path="/don-hang/copy-don-hang"
            exact
            requiredPermission="don_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={CopyDonHang} />
            )}
          />

          <ProtectedRoute
            path="/order/tao_moi_order"
            exact
            requiredPermission="order"
            component={(props) => (
              <HomeTemplate {...props} Component={TaoMoiOrder} />
            )}
          />

          <ProtectedRoute
            path="/order"
            exact
            requiredPermission="order"
            component={(props) => (
              <HomeTemplate {...props} Component={DanhSachOrder} />
            )}
          />

          <ProtectedRoute
            path="/order/chinh-sua-order/:id"
            exact
            requiredPermission="order"
            component={(props) => (
              <HomeTemplate {...props} Component={ChinhSuaOrder} />
            )}
          />
          {/* <ProtectedRoute
          path="/order/chi-tiet-order/:id"
          exact

          component={(props) => <HomeTemplate {...props} Component={ChiTietOrder} />}
        /> */}
          <Route
            exact
            path="/order/chi-tiet-order/:id"
            component={ChiTietOrder}
          />

          <ProtectedRoute
            path="/nhan-don"
            exact
            requiredPermission="nhan_don"
            component={(props) => (
              <HomeTemplate {...props} Component={DanhSachOrderNhanDon} />
            )}
          />

          <ProtectedRoute
            path="/nhan-don/chinh-sua-order-nhan-don/:id"
            exact
            requiredPermission="nhan_don"
            component={(props) => (
              <HomeTemplate {...props} Component={ChinhSuaOrderNhanDon} />
            )}
          />

          <ProtectedRoute
            path="/giao-hang"
            exact
            requiredPermission="giao_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={DanhSachDonShip} />
            )}
          />

          <ProtectedRoute
            path="/giao-hang/them_moi_don_ship"
            exact
            requiredPermission="giao_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={ThemDonShip} />
            )}
          />

          <ProtectedRoute
            path="/giao-hang/chinh-sua-don-ship/:id"
            exact
            requiredPermission="giao_hang"
            component={(props) => (
              <HomeTemplate {...props} Component={ChinhSuaDonShip} />
            )}
          />

          <ProtectedRoute
            path="/ket-thuc"
            exact
            requiredPermission="ket_thuc"
            component={(props) => (
              <HomeTemplate {...props} Component={KetThuc} />
            )}
          />

          <ProtectedRoute
            path="/chi-phi"
            exact
            requiredPermission="chi_phi"
            component={(props) => (
              <HomeTemplate {...props} Component={DanhSachChiPhi} />
            )}
          />

          <ProtectedRoute
            path="/chi-phi/them-moi-chi-phi"
            exact
            requiredPermission="chi_phi"
            component={(props) => (
              <HomeTemplate {...props} Component={ThemChiPhi} />
            )}
          />

          <ProtectedRoute
            path="/chi-phi/chinh-sua-chi-phi"
            exact
            requiredPermission="chi_phi"
            component={(props) => (
              <HomeTemplate {...props} Component={ChinhSuaChiPhi} />
            )}
          />

          <ProtectedRoute
            path="/thong-ke-bao-cao/thong-ke-doanh-thu-chi-phi"
            exact
            requiredPermission="thong_ke_bao_cao"
            component={(props) => (
              <HomeTemplate {...props} Component={ThongKeDoanhThuChiPhi} />
            )}
          />

          <ProtectedRoute
            path="/thong-ke-bao-cao/doanh-thu-theo-nhan-vien"
            exact
            requiredPermission="thong_ke_bao_cao"
            component={(props) => (
              <HomeTemplate {...props} Component={DoanhThuTheoNhanVien} />
            )}
          />

          <ProtectedRoute
            path="/thong-ke-bao-cao/thong-ke-chi-phi"
            exact
            requiredPermission="thong_ke_bao_cao"
            component={(props) => (
              <HomeTemplate {...props} Component={ThongKeChiPhi} />
            )}
          />

          <ProtectedRoute
            path="/thong-ke-bao-cao/thong-ke-khach-hang"
            exact
            requiredPermission="thong_ke_bao_cao"
            component={(props) => (
              <HomeTemplate {...props} Component={ThongKeKhachHang} />
            )}
          />

          <PrivateRoute
            path="*"
            exact
            Component={(props) => (
              <HomeTemplate {...props} Component={NotFound} />
            )}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
