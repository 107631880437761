import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Checkbox,
  Input,
  InputNumber,
  Modal,
} from "antd";
import { debounce } from "lodash";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom"; // Thêm useLocation và useHistory
import dayjs from "dayjs";

import { history } from "../../utils/constants/History";
import {
  LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA,
  LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA,
  THAY_DOI_TONG_CAN_NANG_SAGA,
} from "../../redux/types/NhanDonType";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function DanhSachOrderNhanDon() {
  const dispatch = useDispatch();
  const location = useLocation(); // Lấy thông tin URL hiện tại
  const history = useHistory(); // Để thay đổi URL

  const { totalCountOrder, danhSachTinhTrangOrder, danhSachOrder } =
    useSelector((state) => state.NhanDonReducer);
  const { danhSachNguonHang } = useSelector((state) => state?.NhanDonReducer);

  // Hàm để lấy filters và pagination từ URL
  const getParamsFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return {
      filters: {
        maOrder: params.get("maOrder") || "",
        trackingNumber: params.get("trackingNumber") || "",
        idNguonHang: params.get("idNguonHang") || "",
      },
      pagination: {
        current: parseInt(params.get("page")) || 1,
        pageSize: parseInt(params.get("pageSize")) || 10,
      },
    };
  };

  const [filters, setFilters] = useState(getParamsFromUrl().filters);
  const [pagination, setPagination] = useState(getParamsFromUrl().pagination);

  // Đồng bộ URL khi filters hoặc pagination thay đổi
  const updateUrl = (newFilters, newPagination) => {
    const params = new URLSearchParams();
    if (newFilters.maOrder) params.set("maOrder", newFilters.maOrder);
    if (newFilters.trackingNumber)
      params.set("trackingNumber", newFilters.trackingNumber);
    if (newFilters.idNguonHang)
      params.set("idNguonHang", newFilters.idNguonHang);
    params.set("page", newPagination.current);
    params.set("pageSize", newPagination.pageSize);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA });
    // Fetch initial data based on URL params
    const { filters: urlFilters, pagination: urlPagination } =
      getParamsFromUrl();
    dispatch({
      type: LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA,
      page: urlPagination.current,
      pageSize: urlPagination.pageSize,
      filters: urlFilters,
    });
  }, [dispatch]);

  // Fetch data khi URL thay đổi
  useEffect(() => {
    const { filters: urlFilters, pagination: urlPagination } =
      getParamsFromUrl();
    setFilters(urlFilters);
    setPagination(urlPagination);
    dispatch({
      type: LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA,
      page: urlPagination.current,
      pageSize: urlPagination.pageSize,
      filters: urlFilters,
    });
  }, [location.search, dispatch]);

  const handleSearch = () => {
    const newPagination = { ...pagination, current: 1 }; // Reset về trang 1 khi tìm kiếm mới
    setPagination(newPagination);
    updateUrl(filters, newPagination); // Cập nhật URL
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    updateUrl(filters, newPagination); // Cập nhật URL
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
      width: 10,
    },
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "NH | Mã order | Tracking",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => (
        <>
          <div>
            <span className="fw-bold">{record?.TenNguonHang}</span>
          </div>
          <div>
            Order: <span className="fw-bold">{record?.MaOrder}</span>
          </div>
          <div>
            Tracking: <span className="fw-bold">{record?.TrackingNumber}</span>
          </div>
        </>
      ),
    },
    {
      title: "Số Lượng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => (
        <>
          <div>
            SP: <span className="fw-bold">{record?.SoSanPham}</span> | SĐ:{" "}
            <span className="fw-bold">{record?.SoDon}</span>
          </div>
          <div>
            CN order:{" "}
            <span className="fw-bold">
              {record.CanNang ? record?.CanNang : 0} (lạng)
            </span>
            <Button
              size="small"
              type="primary"
              shape="circle"
              onClick={() => {
                let tongCanDauVao = record.CanNang ? record?.CanNang : 0;
                confirm({
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <h6>Nhập tổng cân đầu vào order {record?.MaOrder}</h6>
                      <input
                        onChange={(e) => {
                          tongCanDauVao = e.target.value;
                        }}
                        defaultValue={tongCanDauVao} // Hiển thị giá trị ban đầu
                        type="text"
                      />{" "}
                      (Lạng)
                      <h6 className="mt-2 text text-warning">
                        Bạn có chắc chắn muốn thay đổi tổng cân nặng không?
                      </h6>
                    </>
                  ),
                  onOk() {
                    dispatch({
                      type: THAY_DOI_TONG_CAN_NANG_SAGA,
                      idOrder: record?.Id,
                      tongCanNang: tongCanDauVao,
                      page: pagination?.current,
                      pageSize: pagination?.pageSize,
                      filters,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </Button>
          </div>
          {(record.TongCanNangByDonHang ? record?.TongCanNangByDonHang : 0) !=
          (record.CanNang ? record?.CanNang : 0) ? (
            <div>
              CN đơn hàng:{" "}
              <span className="fw-bold text-danger">
                {record.TongCanNangByDonHang ? record?.TongCanNangByDonHang : 0}{" "}
                (lạng)
              </span>
            </div>
          ) : (
            <div>
              CN đơn hàng:{" "}
              <span className="fw-bold">
                {record.TongCanNangByDonHang ? record?.TongCanNangByDonHang : 0}{" "}
                (lạng)
              </span>
            </div>
          )}
          <div>
            Tiền cân ĐV:{" "}
            <span className="fw-bold">
              {(record.TongTienCanDauVao ? record?.TongTienCanDauVao : 0)
                ?.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                })
                .replace(/\./g, ",")}
            </span>
          </div>
        </>
      ),
    },
    {
      title: "Tiền",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => (
        <>
          <div>
            Tiền Vào:{" "}
            <span className="fw-bold">
              {record?.TienVao?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: true,
              }).replace(/\./g, ",")}
            </span>
          </div>
          <div>
            Tiền ra:{" "}
            <span className="fw-bold">
              {record?.TienBanRa?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: true,
              }).replace(/\./g, ",")}
            </span>
          </div>
        </>
      ),
    },
    {
      title: "Ngày",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        const NgayNhanHang = record?.NgayNhanHang
          ? dayjs(record?.NgayNhanHang).format("DD/MM/YYYY")
          : "";
        const NgayNhanDonHangDuKien = record?.NgayNhanDonHangDuKien
          ? dayjs(record?.NgayNhanDonHangDuKien).format("DD/MM/YYYY")
          : "";
        const ngayTao = record?.Ngay
          ? dayjs(record?.Ngay).format("DD/MM/YYYY")
          : "";
        return (
          <>
            <div>
              Tạo: <span className="fw-bold">{ngayTao}</span>
            </div>
            <div>
              Dự kiến: <span className="fw-bold">{NgayNhanDonHangDuKien}</span>
            </div>
            <div>
              Nhận: <span className="fw-bold">{NgayNhanHang}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Tình Trạng",
      dataIndex: "TenTinhTrangOrder",
      key: "TenTinhTrangOrder",
      render: (text, record) => (
        <>
          <div>{text}</div>
          <div className="fw-bold">{record?.TenNhanVienOrder}</div>
        </>
      ),
    },
    {
      title: "Chức Năng",
      key: "Select",
      render: (text, record) => (
        <>
          <div>
            <button
              className="btn me-2 btn-primary"
              onClick={() => {
                history.push(
                  `/nhan-don/chinh-sua-order-nhan-don/${record?.Id}`
                );
              }}
            >
              <FormOutlined style={{ fontSize: 17 }} />
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <h3 className="col-md-9">Nhận Đơn</h3>

      <div className="search-filters my-4 row">
        <div className="col-md-2">
          <h6>Mã Order</h6>
          <Input
            value={filters.maOrder}
            onChange={(e) =>
              setFilters({ ...filters, maOrder: e.target.value })
            }
            placeholder="Nhập mã order"
          />
        </div>
        <div className="col-md-2">
          <h6>Tracking Number</h6>
          <Input
            value={filters.trackingNumber}
            onChange={(e) =>
              setFilters({ ...filters, trackingNumber: e.target.value })
            }
            placeholder="Nhập tracking number"
          />
        </div>
        <div className="col-md-2">
          <h6>Chọn Nguồn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            value={filters.idNguonHang}
            onChange={(value) => setFilters({ ...filters, idNguonHang: value })}
            placeholder="Chọn nguồn hàng"
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachNguonHang?.map((item, key) => (
              <Select.Option key={key} value={item?.Id}>
                {item?.Ten}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-2 align-self-end">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            Tìm kiếm
          </Button>
        </div>
      </div>

      <h5 className="col-md-9">Danh Sách Order</h5>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachOrder}
        pagination={{
          ...pagination,
          total: totalCountOrder,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
