export const LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA =
  "LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA";
export const SET_KHACH_HANG_DON_HANG = "SET_KHACH_HANG_DON_HANG";
export const SET_DON_HANG = "SET_DON_HANG";
export const SET_EDIT_DON_HANG = "SET_EDIT_DON_HANG";
export const SET_DANH_SACH_TINH_TRANG_DON_HANG =
  "SET_DANH_SACH_TINH_TRANG_DON_HANG";

export const LAY_DANH_SACH_DON_HANG_SAGA = "LAY_DANH_SACH_DON_HANG_SAGA";

export const THEM_DON_HANG_SAGA = "THEM_DON_HANG_SAGA";
export const XOA_DON_HANG_SAGA = "XOA_DON_HANG_SAGA";
export const CHINH_SUA_DON_HANG_SAGA = "CHINH_SUA_DON_HANG_SAGA";
export const LAY_DANH_SACH_TINH_TRANG_DON_HANG_SAGA =
  "LAY_DANH_SACH_TINH_TRANG_DON_HANG_SAGA";
