import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  Checkbox,
  Input,
  InputNumber,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import {
  LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA,
  LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA,
  RESET_DON_SHIP,
  TAO_DON_SHIP_CHUA_SHIP_SAGA,
  THEM_DON_HANG_DA_CHON_TRANG_DON_SHIP,
  THEM_DON_SHIP_SAGA,
  XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP,
} from "../../redux/types/DonShipType";

const { RangePicker } = DatePicker;

export default function ThemDonShip() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_DON_SHIP });
  }, []);

  const {
    danhSachDonHang,
    totalCountDanhSachDonHang,
    danhSachTinhTrangDonHang,
    danhSachDonViVanChuyen,
  } = useSelector((state) => state.DonShipReducer);

  const { danhSachDonHangDaChon } = useSelector(
    (state) => state.DonShipReducer
  );

  const [donShip, setDonShip] = useState({
    idDonViVanChuyen: 1,
    maVanDon: "",
    ngayShip: new Date(),
    tienCOD: "",
    phiShip: "",
    diaChiNhanHang: danhSachDonHangDaChon[0]?.DiaChiNhanHang || "",
  });

  const [filters, setFilters] = useState({
    maDonHang: "",
    tenKhachHang: "",
    soDienThoai: "",
    idTinhTrang: 5,
    startDate: "",
    endDate: "",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [showTable, setShowTable] = useState(true);
  const [showTableChon, setShowTableChon] = useState(true);

  const handleSearch = () => {
    setPagination({ ...pagination, current: 1 }); // Reset về trang 1 khi tìm kiếm mới
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA });
  }, []);

  useEffect(() => {
    setDonShip({
      ...donShip,
      diaChiNhanHang: danhSachDonHangDaChon[0]?.DiaChiNhanHang,
    });
  }, [danhSachDonHangDaChon]);
  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleSelectOrder = (record) => {
    const exists = danhSachDonHangDaChon.find(
      (order) => order.Id === record.Id
    );
    if (exists) {
      dispatch({ type: XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP, payload: record });
    } else {
      dispatch({ type: THEM_DON_HANG_DA_CHON_TRANG_DON_SHIP, payload: record });
    }
  };

  const handleRemoveSelectedOrder = (record) => {
    dispatch({ type: XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP, payload: record });
  };

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const formatInteger = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDecimal = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1, // Thêm STT
      width: 10,
    },
    {
      title: "Nguồn Hàng",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
      render: (text, record) => {
        return (
          <>
            <div>{record?.MaDonHang}</div>
            <div>{record?.TenNguonHang}</div>
            <div>
              Order{": "}
              {record?.tbOrderChiTiet[0]?.IdOrder}
            </div>
          </>
        );
      },
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
            <div
              style={{
                width: "90px",
                height: "90px",
                border: "1px solid #d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={record?.LinkAnh}
                alt="Product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
        </div>
      ),
    },
    {
      title: "Sản phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div>
            <div>
              <div>
                <span style={{ fontWeight: "bold" }}>{record?.TenSanPham}</span>
                {" | "}
                <span style={{ fontWeight: "bold" }}>
                  {record?.MauSanPham}
                </span>{" "}
                {" | "}
                <span style={{ fontWeight: "bold" }}>{record?.Size}</span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
                {" gam | SL: "}
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>{" "}
                {record?.tbNguonHang?.tbTienTe?.TienTe} | CodeOff:{" "}
                <span style={{ fontWeight: "bold" }}>{record?.CodeOff}%</span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div>
              Giá:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div>
              Cọc:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div>
              Cân:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "HTTT|Ngày",
      dataIndex: "NgayHenTraSanPham",
      key: "NgayHenTraSanPham",

      render: (text, record) => {
        const ngayTao = record?.NgayTao
          ? dayjs(record?.NgayTao).format("DD/MM/YYYY")
          : "";
        const ngayHenTraSanPham = record?.NgayHenTraSanPham
          ? dayjs(record?.NgayHenTraSanPham).format("DD/MM/YYYY")
          : "";

        return (
          <>
            <div>
              HTTT:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {record?.TenHinhThucThanhToan}
                </span>
              </span>
            </div>
            <div>
              Lên:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {ngayTao}
              </span>
            </div>
            <div>
              Hẹn Trả:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {ngayHenTraSanPham}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Khách Hàng",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            {record.LinkFacebook ? (
              <a target="_blank" href={record?.LinkFacebook}>
                <span style={{ fontWeight: "bold", color: "inherit" }}>
                  {record?.TenKhachHang}
                </span>
              </a>
            ) : (
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.TenKhachHang}
              </span>
            )}

            {" ("}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.SoDienThoai}
            </span>
            {") "}
          </div>
          <div style={{ fontWeight: "bold", color: "inherit" }}>
            {record?.DiaChiNhanHang}
          </div>
        </div>
      ),
    },
    {
      title: "Tình Trạng Đơn Hàng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            {record?.IdTinhTrang === 3 ||
            record?.IdTinhTrang === 4 ||
            record?.IdTinhTrang === 8 ||
            record?.IdTinhTrang === 9 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </>
        );
      },
    },

    {
      title: "Chọn",
      key: "Select",
      render: (text, record) => (
        <Checkbox
          checked={
            !!danhSachDonHangDaChon.find((order) => order.Id === record.Id)
          }
          onChange={() => handleSelectOrder(record)}
        />
      ),
    },
  ];

  const selectedOrderColumns = [
    {
      title: "STT",
      key: "index",
      render: (text, record, index) => index + 1, // Thêm STT
      width: 10,
    },
    {
      title: "Nguồn Hàng",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
      render: (text, record) => {
        return (
          <>
            <div>{record?.MaDonHang}</div>
            <div>{record?.TenNguonHang}</div>
            <div>
              Order{": "}
              {record?.tbOrderChiTiet[0]?.IdOrder}
            </div>
          </>
        );
      },
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
            <div
              style={{
                width: "90px",
                height: "90px",
                border: "1px solid #d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={record?.LinkAnh}
                alt="Product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
        </div>
      ),
    },
    {
      title: "Sản phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div>
            <div>
              <div>
                <span style={{ fontWeight: "bold" }}>{record?.TenSanPham}</span>
                {" | "}
                <span style={{ fontWeight: "bold" }}>
                  {record?.MauSanPham}
                </span>{" "}
                {" | "}
                <span style={{ fontWeight: "bold" }}>{record?.Size}</span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
                {" gam | SL: "}
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>{" "}
                {record?.tbNguonHang?.tbTienTe?.TienTe} | CodeOff:{" "}
                <span style={{ fontWeight: "bold" }}>{record?.CodeOff}%</span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div>
              Giá:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div>
              Cọc:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div>
              Cân:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "HTTT|Ngày",
      dataIndex: "NgayHenTraSanPham",
      key: "NgayHenTraSanPham",

      render: (text, record) => {
        const ngayTao = record?.NgayTao
          ? dayjs(record?.NgayTao).format("DD/MM/YYYY")
          : "";
        const ngayHenTraSanPham = record?.NgayHenTraSanPham
          ? dayjs(record?.NgayHenTraSanPham).format("DD/MM/YYYY")
          : "";

        return (
          <>
            <div>
              HTTT:
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {record?.TenHinhThucThanhToan}
                </span>
              </span>
            </div>
            <div>
              Lên:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {ngayTao}
              </span>
            </div>
            <div>
              Hẹn Trả:{" "}
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {ngayHenTraSanPham}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Khách Hàng",
      key: "LinkFacebook",
      render: (text, record) => (
        <div>
          <div>
            {record.LinkFacebook ? (
              <a target="_blank" href={record?.LinkFacebook}>
                <span style={{ fontWeight: "bold", color: "inherit" }}>
                  {record?.TenKhachHang}
                </span>
              </a>
            ) : (
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.TenKhachHang}
              </span>
            )}

            {" ("}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.SoDienThoai}
            </span>
            {") "}
          </div>
          <div style={{ fontWeight: "bold", color: "inherit" }}>
            {record?.DiaChiNhanHang}
          </div>
        </div>
      ),
    },
    {
      title: "Tình Trạng Đơn Hàng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            {record?.IdTinhTrang === 3 ||
            record?.IdTinhTrang === 4 ||
            record?.IdTinhTrang === 8 ||
            record?.IdTinhTrang === 9 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </>
        );
      },
    },
    {
      title: "Hành Động",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-danger"
          type="link"
          onClick={() => handleRemoveSelectedOrder(record)}
        >
          <i className="fa-solid fa-trash"></i> Hủy Chọn
        </button>
      ),
    },
  ];

  const [errors, setErrors] = useState({});

  const handlePhiShipChange = (value) => {
    setDonShip({ ...donShip, phiShip: value });

    // Kiểm tra và cập nhật lỗi ngay khi người dùng thay đổi giá trị
    if ((!value || value < 0) && value != 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phiShip: "Phí Ship là bắt buộc",
      }));
    } else {
      setErrors((prevErrors) => {
        const { phiShip, ...rest } = prevErrors;
        return rest; // Loại bỏ lỗi phiShip nếu giá trị hợp lệ
      });
    }
  };

  const handleMaVanDonChange = (e) => {
    const value = e.target.value;
    setDonShip({ ...donShip, maVanDon: value });

    // if (!value) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     maVanDon: "Mã Vận Đơn là bắt buộc",
    //   }));
    // } else {
    //   setErrors((prevErrors) => {
    //     const { maVanDon, ...rest } = prevErrors;
    //     return rest;
    //   });
    // }
  };

  const handleDiaChiNhanHangChange = (e) => {
    const value = e.target.value;
    setDonShip({ ...donShip, diaChiNhanHang: value });

    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        diaChiNhanHang: "Địa Chỉ Nhận Hàng là bắt buộc",
      }));
    } else {
      setErrors((prevErrors) => {
        const { diaChiNhanHang, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  // Xử lý khi thay đổi phí ship
  const handleCODChange = (value) => {
    setDonShip({ ...donShip, tienCOD: value });

    if ((!value || value < 0) && value != 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tienCOD: "Tiền COD bắt buộc",
      }));
    } else {
      setErrors((prevErrors) => {
        const { tienCOD, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const calculateCOD = () => {
    let tienCOD = 0;
    for (let i = 0; i < danhSachDonHangDaChon?.length; i++) {
      tienCOD +=
        danhSachDonHangDaChon[i]?.GiaBaoVoiKhach +
        danhSachDonHangDaChon[i]?.TongTienCan -
        danhSachDonHangDaChon[i]?.TienCoc;
    }
    // Làm tròn giá trị tienCOD đến số nguyên gần nhất
    tienCOD = Math.round(tienCOD);
    setDonShip({ ...donShip, tienCOD: tienCOD > 0 ? tienCOD : 0 });
  };

  return (
    <div>
      <h3 className="col-md-9">Tạo Đơn Ship</h3>

      <div className="search-filters my-4 row">
        <div className="col-md-2">
          <h6>Tình Trạng Đơn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            placeholder="Tình trạng đơn hàng"
            value={filters.idTinhTrang}
            onChange={(value) => setFilters({ ...filters, idTinhTrang: value })}
          >
            {danhSachTinhTrangDonHang?.map((item, key) => {
              return (
                <Select.Option value={item?.Id} key={key}>
                  {item?.Ten}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="col-md-2">
          <h6>Mã Đơn Hàng</h6>
          <Input
            value={filters.maVanDon}
            onChange={(e) =>
              setFilters({ ...filters, maDonHang: e.target.value })
            }
            placeholder="Nhập Mã Đơn Hàng"
          />
        </div>

        <div className="col-md-2">
          <h6>Tên Khách Hàng</h6>
          <Input
            value={filters.maVanDon}
            onChange={(e) =>
              setFilters({ ...filters, tenKhachHang: e.target.value })
            }
            placeholder="Nhập Tên Khách Hàng"
          />
        </div>

        <div className="col-md-2">
          <h6>SĐT Khách Hàng</h6>
          <Input
            value={filters.maVanDon}
            onChange={(e) =>
              setFilters({ ...filters, soDienThoai: e.target.value })
            }
            placeholder="Nhập SĐT khách hàng"
          />
        </div>

        <div className="col-md-3">
          <h6>Chọn Ngày</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            onChange={(dates) => {
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
                endDate:
                  dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>

        <div className="col-md-1 align-self-end">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          ></Button>
        </div>
      </div>

      <div className="w-100">
        <Button
          type="primary"
          onClick={() => setShowTable(!showTable)}
          style={{ marginBottom: "20px" }}
        >
          {showTable ? "Ẩn Bảng Chọn Đơn Hàng" : "Hiện Bảng Chọn Đơn Hàng"}
        </Button>
      </div>

      {showTable && (
        <Table
          columns={columns}
          rowKey={"Id"}
          dataSource={danhSachDonHang}
          pagination={{
            ...pagination,
            total: totalCountDanhSachDonHang,
          }}
          onChange={handleTableChange}
        />
      )}

      <h4>Danh Sách Đơn Hàng Đã Chọn:</h4>
      <div className="w-100">
        <Button
          type="primary"
          onClick={() => setShowTableChon(!showTableChon)}
          style={{ marginBottom: "20px" }}
        >
          {showTableChon
            ? "Ẩn Bảng Đơn Hàng Đã Chọn"
            : "Hiện Bảng Đơn Hàng Đã Chọn"}
        </Button>
      </div>
      {showTableChon && (
        <Table
          columns={selectedOrderColumns}
          rowKey={"Id"}
          dataSource={danhSachDonHangDaChon}
          pagination={false} // Disable pagination for selected orders table
        />
      )}

      <div className="mt-4">
        <h4>Thông Tin Đơn Ship</h4>
        <div className="row">
          <div className="col-md-4">
            <h6>Chọn Đơn Vị Vận Chuyển</h6>
            <Select
              style={{ width: "100%" }}
              value={donShip.idDonViVanChuyen}
              onChange={(value) =>
                setDonShip({ ...donShip, idDonViVanChuyen: value })
              }
              placeholder="Chọn Đơn Vị Vận Chuyển"
            >
              {danhSachDonViVanChuyen?.map((item, key) => (
                <Select.Option key={key} value={item?.Id}>
                  {item?.DonViVanChuyen}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="col-md-4">
            <h6>Mã Vận Đơn</h6>
            <Input
              value={donShip.maVanDon}
              onChange={handleMaVanDonChange}
              placeholder="Mã Vận Đơn"
            />
            {errors.maVanDon && (
              <div className="text-danger">{errors.maVanDon}</div>
            )}
          </div>

          <div className="col-md-4">
            <h6>Ngày Ship</h6>
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              value={donShip.ngayShip ? dayjs(donShip.ngayShip) : null}
              onChange={(date) =>
                setDonShip({
                  ...donShip,
                  ngayShip: date ? date.format("YYYY-MM-DD") : null,
                })
              }
              placeholder="Chọn Ngày Ship"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <h6>Tiền COD (VND)</h6>
            <div className="row ms-2">
              <InputNumber
                className="col-md-8"
                value={donShip.tienCOD}
                onChange={handleCODChange}
                placeholder="Nhập tiền COD"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />

              <Button className="col-md-3 ms-md-1" onClick={calculateCOD}>
                Lấy tiền COD
              </Button>
              {errors.tienCOD && (
                <div className="text-danger">{errors.tienCOD}</div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <h6>Phí Ship (VND)</h6>
            <InputNumber
              style={{ width: "100%" }}
              value={donShip.phiShip}
              onChange={handlePhiShipChange}
              placeholder="Nhập Phí Ship"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
            {errors.phiShip && (
              <div className="text-danger">{errors.phiShip}</div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <h4>Thông Tin Người Nhận</h4>
        <div className="row">
          <div className="col-md-4">
            <h6>Tên Khách hàng</h6>
            <Input
              value={danhSachDonHangDaChon[0]?.TenKhachHang}
              disabled
              placeholder="Tên Khách Hàng"
            />
          </div>

          <div className="col-md-4">
            <h6>Số Điện Thoại</h6>
            <Input
              value={danhSachDonHangDaChon[0]?.SoDienThoai}
              disabled
              placeholder="Số Điện Thoại"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-8">
            <h6>Địa Chỉ </h6>
            <Input
              value={donShip?.diaChiNhanHang}
              onChange={handleDiaChiNhanHangChange}
              placeholder="Địa Chỉ Nhận Hàng"
            />
            {errors.diaChiNhanHang && (
              <div className="text-danger">{errors.diaChiNhanHang}</div>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <Button
          htmlType="submit"
          size="large"
          className="col-lg-5 me-lg-3"
          style={{
            backgroundColor: "#1890ff",
            color: "white",
          }}
          onClick={() => {
            let newErrors = {};

            // Kiểm tra lỗi cho các trường bắt buộc
            if (
              (!donShip.phiShip || donShip.phiShip < 0) &&
              donShip.phiShip != 0
            ) {
              newErrors.phiShip = "Phí Ship là bắt buộc";
            }

            if (
              (!donShip.tienCOD || donShip.tienCOD < 0) &&
              donShip?.tienCOD != 0
            ) {
              newErrors.tienCOD = "Tiền COD là bắt buộc";
            }

            if (!donShip.diaChiNhanHang) {
              newErrors.diaChiNhanHang = "Địa Chỉ Nhận Hàng là bắt buộc";
            }

            if (Object.keys(newErrors).length > 0) {
              setErrors(newErrors);
              return; // Dừng việc dispatch nếu có lỗi
            }

            // Nếu không có lỗi, dispatch hành động
            setErrors({});
            dispatch({
              type: THEM_DON_SHIP_SAGA,
              donShip,
              idKhachHang: danhSachDonHangDaChon[0]?.IdKhachHang,
              danhSachDonHang: danhSachDonHangDaChon,
            });
          }}
        >
          Tạo Đơn và gửi ship
        </Button>
        <Button
          htmlType="submit"
          size="large"
          className="col-lg-6 mt-3 mt-lg-0"
          style={{
            backgroundColor: "#ff7c00",
            color: "white",
          }}
          onClick={() => {
            let newErrors = {};

            // Kiểm tra lỗi cho các trường bắt buộc
            if (
              (!donShip.phiShip || donShip.phiShip < 0) &&
              donShip.phiShip != 0
            ) {
              newErrors.phiShip = "Phí Ship là bắt buộc";
            }

            if (
              (!donShip.tienCOD || donShip.tienCOD < 0) &&
              donShip?.tienCOD != 0
            ) {
              newErrors.tienCOD = "Tiền COD là bắt buộc";
            }

            if (!donShip.diaChiNhanHang) {
              newErrors.diaChiNhanHang = "Địa Chỉ Nhận Hàng là bắt buộc";
            }

            if (Object.keys(newErrors).length > 0) {
              setErrors(newErrors);
              return; // Dừng việc dispatch nếu có lỗi
            }

            // Nếu không có lỗi, dispatch hành động
            setErrors({});
            dispatch({
              type: TAO_DON_SHIP_CHUA_SHIP_SAGA,
              donShip,
              idKhachHang: danhSachDonHangDaChon[0]?.IdKhachHang,
              danhSachDonHang: danhSachDonHangDaChon,
            });
          }}
        >
          Tạo đơn và chưa ship đi(lưu kho/chờ thêm đồ)
        </Button>
      </div>
    </div>
  );
}
