import {
  SET_DANH_SACH_TINH_TRANG_DON_HANG,
  SET_DON_HANG,
  SET_EDIT_DON_HANG,
  SET_KHACH_HANG_DON_HANG,
} from "../types/DonHangType";

const initialState = {
  danhSachDonHang: [],
  donHangEdit: {},
  totalCount: 0,
  danhSachTinhTrangDonHang: [],
  khachHang: {},
};

export const DonHangReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DON_HANG: {
      state.danhSachDonHang = action.danhSachDonHang;
      state.totalCount = action?.totalCount;
      return { ...state };
    }

    case SET_EDIT_DON_HANG: {
      state.donHangEdit = action?.donHangEdit;
      return { ...state };
    }

    case SET_DANH_SACH_TINH_TRANG_DON_HANG: {
      state.danhSachTinhTrangDonHang = action?.danhSachTinhTrangDonHang;
      return { ...state };
    }

    case SET_KHACH_HANG_DON_HANG: {
      state.khachHang = action.khachHang;
      return { ...state };
    }

    default:
      return state;
  }
};
