import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';

import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { Notification } from '../../utils/Notifications/Notification';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { CHINH_SUA_DON_SHIP_SAGA, CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA, LAY_CHI_TIET_DON_SHIP_SAGA, LAY_CHI_TIET_DON_SHIP_SAGA_V2, LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA, LAY_DANH_SACH_DON_SHIP_SAGA, LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA, LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA, SET_DANH_SACH_DON_HANG_TRANG_DON_SHIP, SET_DANH_SACH_DON_SHIP, SET_DANH_SACH_DON_VI_VAN_CHUYEN, SET_DANH_SACH_TINH_TRANG_DON_SHIP, SET_DON_SHIP_EDIT, TAO_DON_SHIP_CHUA_SHIP_SAGA, THEM_DON_SHIP_SAGA, XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP, XOA_DON_SHIP_SAGA, XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP, XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRANG_SHIP_SAGA } from '../types/DonShipType';
import { donShipService } from '../../services/donShipService';
import { history } from '../../utils/constants/History';

// import { history } from '../../utils/constants/History';

function* layDanhSachDonShip(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donShipService.layDanhDonShipPhanTrangAPI(page, pageSize, filters?.startDate, filters?.endDate, filters?.maVanDon, filters?.idDonViVanChuyen);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_DON_SHIP,
                danhSachDonShip: data?.data?.data,
                totalCount: data?.data?.totalCount,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Danh Sách Đơn Ship thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachDonShipSaga() {
    yield takeLatest(LAY_DANH_SACH_DON_SHIP_SAGA, layDanhSachDonShip)
}

function* layDanhSachDonHangSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donShipService.layDanhSachDonHangPhanTrangAPI(page, pageSize, filters?.idTinhTrang, filters?.maDonHang, filters?.soDienThoai, filters?.tenKhachHang, filters?.startDate, filters?.endDate);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_DON_HANG_TRANG_DON_SHIP,
                danhSachDonHang: data?.data?.data,
                totalCountDanhSachDonHang: data?.data?.totalCount,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Đơn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachDonHangSaga() {
    yield takeLatest(LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA, layDanhSachDonHangSaga)
}

function* layDanhSachDonViVanChuyenSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donShipService.layDanhSachDonViVanChuyenAPI();
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_DON_VI_VAN_CHUYEN,
                danhSachDonViVanChuyen: data?.data?.danhSachDonViVanChuyen,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Đơn Vị Vận Chuyển thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoLayDanhSachDonViVanChuyenSaga() {
    yield takeLatest(LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA, layDanhSachDonViVanChuyenSaga)
}

function* themDonShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { donShip } = action;
        const { data, status } = yield call(() => {
            return donShipService.taoDonShipAPI(donShip?.idDonViVanChuyen, donShip?.maVanDon, donShip?.tienCOD, donShip?.phiShip, action?.idKhachHang, action?.danhSachDonHang, donShip?.ngayShip, donShip?.diaChiNhanHang);
        });


        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP
            })
            history.push('/giao-hang')
            Notification(NOTIFICATION_TYPE.success, "Tạo Đơn Ship Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Tạo Đơn Ship Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoThemDonShipSaga() {
    yield takeLatest(THEM_DON_SHIP_SAGA, themDonShipSaga)
}

function* xoaDonShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return donShipService.xoaDonShipAPI(action?.id);
        })

        const { filters, page, pageSize } = action

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_DON_SHIP_SAGA,
                page: page || 1,
                pageSize: pageSize || 30,
                filters: filters || {},
            })
            Notification(NOTIFICATION_TYPE.success, "Xóa Đơn Ship thành công!");
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Xóa Đơn Ship thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoXoaDonShipSaga() {
    yield takeLatest(XOA_DON_SHIP_SAGA, xoaDonShipSaga)
}

function* layChiTietDonShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return donShipService.layDonShipChiTietAPI(action?.id);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DON_SHIP_EDIT,
                danhSachDonHangDaChon: data?.data?.danhSachDonHangDaChon,
                donShipEdit: data?.data?.donShipFromDb,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Thông tin chi tiết Đơn Ship Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayChiTietDonShipSaga() {
    yield takeLatest(LAY_CHI_TIET_DON_SHIP_SAGA, layChiTietDonShipSaga)
}

function* xoaThayDoiDonHangTrangShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donShipService.xoaVaThayDoiTrangThaiAPI(action?.idDonShip, action?.idDonHang, action?.idTrangThai);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({ type: XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP, payload: action?.record });

            Notification(NOTIFICATION_TYPE.success, "xóa đơn hàng trong đơn ship và thay đổi Trạng Thái Thành công!")
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "xóa đơn hàng trong đơn ship và thay đổi Trạng Thái Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoXoaThayDoiDonHangTrangShipSaga() {
    yield takeLatest(XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRANG_SHIP_SAGA, xoaThayDoiDonHangTrangShipSaga)
}

function* chinhSuaDonShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { donShip } = action;
        const { data, status } = yield call(() => {
            return donShipService.chinhSuaDonShipAPI(donShip?.idDonViVanChuyen, donShip?.maVanDon, donShip?.tienCOD, donShip?.phiShip, action?.idKhachHang, action?.danhSachDonHang, donShip?.ngayShip, action?.idDonShip, donShip?.diaChiNhanHang);
        });


        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP
            })
            history.goBack();
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Đơn Ship Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Đơn Ship Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaDonShipSaga() {
    yield takeLatest(CHINH_SUA_DON_SHIP_SAGA, chinhSuaDonShipSaga)
}

function* chinhSuaTrangThaiDonShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return donShipService.chinhSuaTrangThaiDonShipAPI(action?.idDonShip, action?.idTrangThai);
        });
        const { filters, page, pageSize } = action;


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_DON_SHIP_SAGA,
                page: page || 1, // Nếu không có page thì mặc định là 1
                pageSize: pageSize || 30, // Nếu không có pageSize thì mặc định là 30
                filters: filters || {}, //
            })

            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Trạng Thái Đơn Ship Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Đơn Ship Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaTrangThaiDonShipSaga() {
    yield takeLatest(CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA, chinhSuaTrangThaiDonShipSaga)
}

function* layChiTietDonShipSagaV2(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return donShipService.layDonShipChiTietAPI(action?.id);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DON_SHIP_EDIT,
                danhSachDonHangDaChon: data?.data?.danhSachDonHangDaChon,
                donShipEdit: data?.data?.donShipFromDb,
            })
            if (action.callback) {
                action.callback();
            }
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Thông tin chi tiết Đơn Ship Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayChiTietDonShipSagaV2() {
    yield takeLatest(LAY_CHI_TIET_DON_SHIP_SAGA_V2, layChiTietDonShipSagaV2)
}

function* layDanhSachTinhTrangDonShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return donShipService.layDanhSachTinhTrangDonShipAPI();
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_TINH_TRANG_DON_SHIP,
                danhSachTinhTrangDonShip: data?.data?.danhSachTinhTrangDonShip,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Tình Trạng Đơn Ship", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoLayDanhSachTinhTrangDonShipSaga() {
    yield takeLatest(LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA, layDanhSachTinhTrangDonShipSaga)
}

function* taoDonShipChuaShipSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { donShip } = action;
        const { data, status } = yield call(() => {
            return donShipService.taoDonShipChuaShipAPI(donShip?.idDonViVanChuyen, donShip?.maVanDon, donShip?.tienCOD, donShip?.phiShip, action?.idKhachHang, action?.danhSachDonHang, donShip?.ngayShip, donShip?.diaChiNhanHang);
        });


        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP
            })
            history.push('/giao-hang')
            Notification(NOTIFICATION_TYPE.success, "Tạo Đơn Ship Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Tạo Đơn Ship Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoTaoDonShipChuaShipSaga() {
    yield takeLatest(TAO_DON_SHIP_CHUA_SHIP_SAGA, taoDonShipChuaShipSaga)
}